<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row class="match-height">
        <!-- list -->
        <b-col
          lg="12"
        >
          <b-overlay
            :show="ShowList"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <b-icon-controller
                  font-scale="3"
                  animation="cylon"
                />
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>

            <b-card no-body>
              <b-card-body>
                <div class="p-1">
                  <h3>รายการที่ถูกรางวัล</h3>
                </div>
              </b-card-body>
              <b-table

                striped
                hover
                responsive
                class="position-relative items-center"
                :per-page="perPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
              >
                <template #cell(index)="data">
                  {{ perPage * (currentPage - 1) + (data.index + 1) }}
                </template>
                <template #cell(detail)="data">
                  <p
                    v-for="(itemss) in data.item.data"
                    :key="itemss"
                  >
                    {{ itemss.LottoHeadName }} | {{ itemss.name_type }} @{{ itemss.bet }} {{ itemss.amount }}฿ = +{{ Commas(itemss.amount * itemss.win_rate) }} บาท
                  </p>
                </template>
                <template #cell(amount_show)="data">
                  {{ Commas(data.item.data.reduce((prev, curr) => prev + curr.amount, 0)) }} บาท
                </template>
                <template #cell(sum)="data">
                  {{ Commas(data.item.data.reduce((prev, curr) => prev + (curr.amount * curr.win_rate), 0)) }} บาท
                </template>
              </b-table>

              <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group
                  label="Per Page"
                  label-cols="8"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1 ml-1 pb-1"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    inline
                    :options="pageOptions"
                    @change="GetLottolist()"
                  />
                </b-form-group>

                <!-- pagination -->
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                    @input="GetLottolist()"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>
            </b-card>

          </b-overlay>
        </b-col>

      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  // BFormInput,
  // BButton,
  BPagination,
  BTable,
  // BInputGroupPrepend,
  BFormSelect,
  // BProgress,
  // BBadge,
  // BTabs,
  // BTab,
  BOverlay,
  BIconController,

} from 'bootstrap-vue'
// import moment from 'moment-timezone'
// import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
// import moment from 'moment-timezone'
// import {
//   required, integer, between, length,
// } from '@validations'

export default {
  components: {
    // vSelect,
    // BInputGroupPrepend,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BTable,
    ValidationObserver,
    // BProgress,
    // BBadge,
    // BTabs,
    // BTab,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      ShowLog: false,
      ShowCancle: false,
      ShowList: false,
      totalRows: 1,
      currentPage: 1,
      items: [],
      totalRowsLog: 1,
      currentPageLog: 1,
      itemsLog: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      pageOptions: [10, 15, 20],
      perPage: 10,
      fields: [
        { key: 'index', label: 'ลำดับที่' },
        { key: 'detail', label: 'รายละเอียด' },
        { key: 'amount_show', label: 'ยอดเดิมพัน' },
        { key: 'sum', label: 'ได้เงิน' },
        { key: 'remark', label: 'ชื่อบิล' },
        // { key: 'amount', label: 'เงินเดิมพัน' },
        // { key: 'dis_remark', label: 'ส่วนลด' },
        // { key: 'status', label: 'สถานะ' },
        // { key: 'timestamp', label: 'เวลาแทง' },
        // { key: 'actions', label: 'Actions' },
      ],
      fieldsLog: [
        { key: 'index', label: 'ลำดับที่' },
        { key: '_id', label: 'โพยที่' },
        { key: 'remark', label: 'ชื่อลูกค้า' },
        { key: 'LottoHeadName', label: 'ประเภทหวย' },
        { key: 'CloseDateName', label: 'งวดประจำวันที่' },
        { key: 'amount', label: 'เงินเดิมพัน' },
        { key: 'dis_remark', label: 'ส่วนลด' },
        { key: 'status', label: 'สถานะ' },
        { key: 'timestamp', label: 'เวลาแทง' },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.GetLottolist()
    // this.GetCancle()
  },
  methods: {
    GetLottolist() {
      this.ShowList = true
      // const index = 0
      this.ShowList = true
      const params = {
        page: this.currentPage,
        perpage: this.perPage,
        id: this.$route.params.id,
        UserToken: localStorage.getItem('UserToken'),
      }
      this.$http
        .post(
          'https://api.rmtlotto.com/api/member/history/LottoWinDetailListMember',
          params,
        )
        .then(response => {
          this.onFiltered(response.data)
          this.ShowList = false
        })
        .catch(error => {
          console.log(error)
          this.ShowList = false
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.Mes
      this.totalRows = filteredItems.total
    },
    GetCancle() {
      // const index = 0
      this.ShowCancle = true
      const params = {
        page: this.currentPageLog,
        perpage: this.perPage,
        UserToken: localStorage.getItem('UserToken'),
      }
      this.$http
        .post(
          'https://api.rmtlotto.com/api/member/history/LottoCancelList',
          params,
        )
        .then(response => {
          this.onFilteredLog(response.data)
          this.ShowCancle = false
        })
        .catch(error => {
          console.log(error)
          this.ShowCancle = false
        })
    },
    onFilteredLog(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.itemsLog = filteredItems.Mes
      this.totalRowsLog = filteredItems.total
    },
    Commas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
